<template>
	<div class="full-height">
		<div
			class="full-height flex-column overflow-y-auto mb-10"
		>
			<div
				class="justify-space-between gap-10 full-height"
			>
				<div class="flex-1 bg-white pa-10 full-height">
					<h6 class="under-line-identify">계정정보</h6>
					<table class="table th-left td-left">
						<col width="120px">
						<col width="auto">
						<col width="120px">
						<col width="auto">
						<tbody>
						<tr>
							<th>아이디</th>
							<td>
								{{ user.account_id }}
							</td>
							<th>가입일</th>
							<td class="position-relative">
								{{ item.join_date }}
							</td>
						</tr>
						<tr>
							<th>상호명 <span class="color-red">*</span></th>
							<td>
								{{ user.account_name }}
							</td>
							<th>휴대폰 번호</th>
							<td>
								{{ item.account_phone_number }}
							</td>
						</tr>
						<tr>
							<th>비밀번호 변경</th>
							<td colspan="3" class="text-right">
								<button
									class="bg-identify pa-5-10"

									@click="onPassword"
								>비밀번호 변경</button>
							</td>
						</tr>
						<tr>
							<th>PIN</th>
							<td colspan="3" class="text-right">
								<button
									class="bg-identify pa-5-10"

									@click="onPin"
								>PIN 설정</button>
							</td>
						</tr>
						</tbody>
					</table>

					<h6 class="under-line-identify mt-10">사업자 정보</h6>
					<table class="table th-left td-left">
						<col width="120px">
						<col width="auto">
						<col width="120px">
						<col width="auto">
						<tbody>
						<tr>
							<th>사업자 구분 <span class="color-red">*</span></th>
							<td colspan="3">
								<template
									v-for="code in codes.B002.items"
								>
									<span
										v-if="code.total_code == item.business_type"
										:key="code.total_code"

										class="pa-5-10"
										:class="item.business_type == code.total_code ? 'bg-green' : 'bg-default'"

									>{{ code.code_name }}</span>
								</template>
							</td>
						</tr>
						<tr
							v-if="item.business_type == 'B002002'"
						>
							<th>사업자등록번호 <span class="color-red">*</span></th>
							<td colspan="3">
								{{ item.front }}
							</td>
						</tr>
						<tr
							v-if="item.business_type == 'B002001'"
						>
							<th>사업자등록번호 <span class="color-red">*</span></th>
							<td>
								{{ item.front }}
							</td>
							<th>법인등록번호 <span class="color-red">*</span></th>
							<td>
								{{ item.back }}
							</td>
						</tr>
						<tr
							v-if="item.business_type == 'B002003'"
						>
							<th>주민등록 번호 <span class="color-red">*</span></th>
							<td colspan="3">
								{{ item.front }}
								{{ item.back }}
							</td>
						</tr>

						<tr>
							<th>서비스명 <span class="color-red">*</span></th>
							<td>
								{{ item.shop_name }}
							</td>
							<th>대표자명 <span class="color-red">*</span></th>
							<td>{{ item.shop_ceo }}</td>
						</tr>
						<tr>
							<th>사업장 주소 <span class="color-red">*</span></th>
							<td colspan="3">

								{{ item.shop_zip_code }}
								{{ item.shop_address }}
								{{ item.shop_address_detail }}
							</td>
						</tr>
						</tbody>
					</table>


					<div
						v-if="false"
					>
						<h6 class="mt-10">정산 정보</h6>
						<table class="table th-left td-left">

							<col width="120px">
							<col width="auto">
							<col width="120px">
							<col width="auto">
							<tbody>
							<tr>
								<th>정산주기</th>
								<td
									class="text-left"
									colspan="3"
								>영업일 기준 / 월 정산</td>
							</tr>
							<tr>
								<th>은행</th>
								<td>
									{{ item.bank_name }}
								</td>
								<th>계좌번호</th>
								<td>
									{{ item.bank_account }}
								</td>
							</tr>
							<tr>
								<th>예금주</th>
								<td>
									{{ item.bank_holder }}
								</td>
								<th>이메일</th>
								<td>
									<input
										v-model="item.bank_email"
										type="email"
										class="input-box full-width"
										placeholder="세금계산서 발행 이메일"
										maxlength="50"
									/>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div
					class="flex-2 bg-white full-height flex-column pa-10 overflow-y-auto"
				>
					<h6 class="">지갑 정보</h6>
					<div
						v-if="item_wallet.uid"
					>
						<table class="table">
							<tbody>
							<tr>
								<th>지갑</th>
								<th>지갑 주소</th>
								<th>잔고</th>
								<th v-if="false">관리</th>
							</tr>
							<tr>
								<td>{{ item_wallet.virtual_name }}</td>
								<td>{{ item_wallet.virtual_account ? item_wallet.virtual_account : '-'}}</td>
								<td>{{ item_wallet.balance | makeComma }}원</td>
								<td v-if="false">
									<button class="mr-10" @click="postRefresh"><v-icon small class="color-primary  mdi mdi-refresh"></v-icon></button>
									<button
										class="btn-inline btn-primary"
										@click="onPinWithdrawal"
									>출금</button>
								</td>
							</tr>
							</tbody>
						</table>

						<Search
							:search="item_search"
							:option="search_option"

							@change="getHistory"
							@click="getHistory"
							@toExcel="toExcel"
							class="mt-10"
						>
							<select
								slot="add"
								v-model="item_search.virtual_uid"
								class="mr-10 input-box-inline size-px-12"
								@change="getSearch(1)"
							>
								<option value="">지갑 구분</option>
								<option
									v-for="(virtual, v_index) in items_virtual"
									:key="'virtual_' + v_index"
									:value="virtual.uid"
								>{{ virtual.virtual_name }}</option>
							</select>
							<select
								slot="add"
								v-model="item_search.send_type"
								class="pa-5-10 mr-10"

								@change="getSearch(1)"
							>
								<option value="">전송 구분</option>
								<template
									v-for="(code, index) in codes.W001.items"
								>
									<option
										:key="code.total_code + '_' + index"
										:value="code.code_value"
									>{{ code.code_name }}</option>
								</template>
							</select>
							<select
								slot="add"
								v-model="item_search.status"
								class="pa-5-10 mr-10"

								@change="getSearch(1)"
							>
								<option value="">승인 상태</option>
								<template
									v-for="(code, index) in codes.U001.items"
								>
									<option
										:key="code.total_code + '_' + index"
										:value="code.code_index"
									>{{ code.code_name }}</option>
								</template>
							</select>
						</Search>
						<div
							v-if="items_history.length > 0"
						>
							<table class="table table-even mt-10">
								<colgroup>
								</colgroup>
								<thead>
								<tr>
									<th>전송 구분</th>
									<th>구분</th>
									<th>승인 상태</th>
									<th>금액</th>
									<th>메모</th>
									<th>날자</th>
								</tr>
								</thead>
								<tbody>
								<tr
									v-for="(wallet, w_index) in wallet_history"
									:key="'wallet_' + w_index"
								>
									<td>{{ wallet.send_type_name }}</td>
									<td :class="'color-' + wallet.history_type_color">{{ wallet.history_type_name }}</td>
									<td :class="'color-' + wallet.status_color">{{ wallet.status_name }}</td>
									<td :class="'color-' + wallet.history_type_color">{{ wallet.amount | makeComma }} 원</td>
									<td class="word-break text-left" :inner-html.prop="wallet.memo | nl2br"></td>
									<td>{{ wallet.wDate }}</td>
								</tr>
								</tbody>
							</table>

							<Pagination
								:program="program"
								:align="'center'"
								:options="item_search"

								class="mt-auto"
								@click="getSearch"
							></Pagination>
						</div>
						<Empty
							v-else
						></Empty>
					</div>

					<div
						v-else
					>
						<button
							v-if="!auth.is_admin"
							class="btn-inline btn-primary"

							@click="postWallet"
						>지갑 생성</button>
					</div>
				</div>
			</div>
		</div>

		<div
			class="justify-center"
		>
		</div>
		<DaumPost
			:overlay="is_post"
			:config="daum_config"
			@callBack="addPost"
		></DaumPost>

		<Modal
			:is_modal="is_password"
			:option="modal_option"

			width="320px"
			height="240px"

			@click="putPassword"
			@close="clearPassword"
			@cancel="clearPassword"
		>
			<div
				slot="modal-title"
				class="pa-10 text-center bg-white under-line"
			><h6>비밀번호 변경</h6></div>
			<div
				slot="modal-content"
			>
				<div>
					<input
						v-model="item_password.account_password_old"
						type="password"
						class="box pa-10 width-100" placeholder="기존 비밀번호"
					/>
				</div>
				<div class="mt-10">
					<input
						v-model="item_password.account_password"
						type="password"
						class="box pa-10 width-100" placeholder="신규 비밀번호"
					/>
				</div>
				<div class="mt-10">
					<input
						v-model="item_password.account_password_confirm"
						type="password"
						class="box pa-10 width-100" placeholder="비밀번호 확인"
					/>
				</div>
			</div>
		</Modal>

		<AgencyWithdrawal
			v-if="is_on_withdrawal"
			:user="user"

			@click="is_on_withdrawal = false; getData(); getHistory();"
			@cancel="is_on_withdrawal = false"
		></AgencyWithdrawal>

	</div>
</template>

<script>

import DaumPost from "@/components/Daum/DaumPost";
import '@toast-ui/editor/dist/toastui-editor.css';
import Modal from "@/components/Modal";
import Empty from "@/view/Layout/Empty";
import Search from "@/view/Layout/Search";
import Pagination from "@/components/Pagination";
import AgencyWithdrawal from "@/view/Agency/AgencyWithdrawal";

export default {
	name: 'MypageAgency'
	, components: {AgencyWithdrawal, Pagination, Search, Empty, DaumPost, Modal}
	, props: ['Axios', 'user', 'codes', 'rules', 'date', 'TOKEN', 'auth']
	, data: function(){
		return {
			program: {
				name: '마이 페이지'
				, top: true
				, title: true
				, bottom: false
			}
			,item: {
				agency_type: ''
				,business_type: 'B002001'
				,bank_code: ''
				,join_date: this.date.getToday('-')
				,delivery_type: '0'
				,shop_return: ''
			}
			, item_pg: {

			}
			,is_data_pick: false
			,is_modal: false
			,is_post: false
			,daum_config: {
				width: '360px'
			}
			,modal_option: {
				title: false
				,bottom: true
				, top: true
			}
			, is_password: false
			, item_password: {
				account_password: ''
				, account_password_confirm: ''
				, account_password_old: ''
			}
			, upload_files: []
			, item_logo_img: null
			, item_upload_logo_img: {}
			, history_list: []
			, items_history: []
			, item_wallet: {}
			, item_search: {
				page: 1
				, list_cnt: 10
				, send_type: ''
				, history_type: ''
				, status: ''
				, sDate: this.$date.getLastDate(7,'-')
				, eDate: this.$date.getToday('-')
				, tCnt: 0
				, type: 'my'
				, virtual_uid: ''
			}
			, search_option: {
				is_cnt: true
				, is_excel: true
				, cnt: 0
				, tCnt: 0
				, sDate: true
				, eDate: true
				, not_search: true
			}
			, is_excel: false
			, excel_data: {
				name: '입출금 내역'
				,header: [
					{ key: 0, name: '지갑구분', column: 'virtual_name'}
					,{ key: 0, name: '전송구분', column: 'send_type_name'}
					,{ key: 0, name: '구분', column: 'history_type_name'}
					,{ key: 0, name: '금액', column: 'amount'}
					,{ key: 0, name: '메모', column: 'memo'}
					,{ key: 0, name: '등록일', column: 'wDate'}
				]
				,content: null
			}
			, is_on_withdrawal: false
			, items_bank: []
			, is_on_pin: false
			, items_virtual: []
		}
	}
	,computed: {
		logo_img_name: function(){
			let name = '로고 이미지'
			return name
		}
		, sales_fee: function(){
			let t = 0

			t += Number(this.item.sales_fee)

			if(this.item.upper_sales_fee){
				t += Number(this.item.upper_sales_fee)
			}

			if(this.item_pg.uid){
				t += Number(this.item_pg.pg_fee)
			}

			return t
		}
		, text_pg_title: function(){
			let t = ''
			switch (this.user.agency_type){
				case  'A001001':
					t = '보유 PG 정보'
					break
				case 'A001002':
					t = 'PG 공급가 정보'
					break
				case 'A001003':
					t = '결제 PG 정보'
					break
			}
			return t
		}
		, wallet_history: function(){
			return this.items_history.filter( (item) => {
				this.codes["W001"].items.filter( (status) => {
					if(item.send_type == status.code_index){

						item.send_type_name = status.code_name
						item.send_type_color = status.code_color
					}
				})
				switch (item.history_type){
					case '0':
						item.history_type_name = '출금'
						item.history_type_color = 'red'
						item.amount *= -1
						break;
					case '1':
						item.history_type_name = '입금'
						item.history_type_color = 'blue'
						break;
				}
				this.codes["U001"].items.filter( (status) => {
					if(item.status == status.code_index){

						item.status_name = status.code_name
						item.status_color = status.code_color
					}
				})
				return item
			})
		}
	}
	, methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getAgency'
					,data: {
						shop_uid: this.user.shop_uid
					}
					, version: 'v2'
				})
				if(result.success){
					this.item = result.data.info
					this.item_wallet = result.data.wallet_info
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, save: async function(){
			try{

				this.$bus.$emit('on', true)

				if(this.item_logo_img){
					this.$set(this.item, 'item_logo_img', this.item_logo_img)
				}

				const result = await this.$request.init({
					method: 'post'
					,url: 'management/putAgencyMyInfo'
					,data: {
						bank_email: this.item.bank_email
						, pg_code: this.item.pg_code
						, pgMerchNo: this.item.pgMerchNo
						, pgMerchName: this.item.pgMerchName
						, pg_fee: this.item.pg_fee
						, item_logo_img: this.item.item_logo_img
					}
				})
				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message})
					await this.getData()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		,daumPost: function () {
			this.is_post = true
		}
		, addPost: function (call) {

			this.$set(this.item, 'shop_zip_code', call.zonecode)
			this.$set(this.item, 'shop_address', call.address)

			this.is_post = false
		}
		, onPassword: function(){
			this.is_password = true
		}

		, putPassword: async function(){
			try{
				const result = await this.$request.init({
					method: 'post'
					, url: 'management/putPassword'
					, data: {
						account_type: this.user.account_type_code
						, account_uid: this.user.account_uid
						, account_password_old: this.item_password.account_password_old
						, account_password: this.item_password.account_password
						, account_password_confirm: this.item_password.account_password_confirm
					}
				})
				if(result.success){
					this.$bus.$emit('notify',{ type: 'success', message: this.$language.common.success})
					this.clearPassword()
				}else{
					throw result.message
				}
			}catch(e){
				console.log(e)
				this.$bus.$emit('notify',{ type: 'error', message: e})
			}
		}
		, clearPassword: function(){
			this.is_password = false
			this.item_password = {
				account_password: ''
				, account_password_confirm: ''
				, account_password_old: ''
			}
		}
		, setFile2: function(e){
			console.log('setFile2', e)

			for(let file of e){
				console.log(`file` , file)
				this.item_logo_img = file

				const reader = new FileReader()
				let data = {
					name: file.name
					, size: file.size
					, type: file.type
				}

				reader.onload = (e) => {
					console.log('reader.onload')
					data.src = e.target.result
					this.item_upload_logo_img = data
				}

				reader.readAsDataURL(file)
			}
		}
		, removeFile: async function(){
			if(this.item_logo_img){

				if(this.item.shop_logo){
					this.item_upload_logo_img = {
						src: this.item.shop_logo
						, name: this.item.shop_logo
						, type: 'image'
					}
					this.item_logo_img = null
				}else{
					this.item_upload_logo_img = {}
					this.item_logo_img = null
				}
			}else{
				if(confirm("삭제하시겠습니까?")){

					try{
						const result = await this.$request.init({
							method: 'post'
							,url: 'management/postShopLogoDelete'
							,data: {
								shop_uid: this.item.uid
							}
						})

						if(result.success){
							this.item_upload_logo_img = {}
							this.item_logo_img = null
						}else{
							this.$bus.$emit('notify', { type: 'error', message: result.message })
						}
					}catch (e) {
						console.log(e)
					}
				}
			}
		}
		, postWallet: async function(){
			try{
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postWalletAgency'
				})

				if(result.success){
					await this.getData()
					await this.getHistory()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}
		}
		, getHistory: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getAgencyWalletHistory'
					, data: this.item_search
				})

				if(result.success){
					this.items_history = result.data.result
					this.item_search.tCnt = result.data.tCnt
					this.search_option.tCnt = result.data.tCnt
					this.$set(this.item_search, 'total_count', result.data.tCnt)
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,toExcel: function(){
			this.excel_data.content = this.list_history
			this.is_excel = true
		}
		, getSearch: function(page){
			this.item_search.page = page
			this.getHistory()
		}
		, postRefresh: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postAgencyWalletRefresh'
					, data: {
						account_id: this.user.account_id
					}
				})

				if(result.success){
					this.item_wallet = result.data
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onWithdrawal: function(){
			this.is_on_withdrawal = true
		}
		, onPin: function(){
			this.$bus.$off('pinCallback')

			this.$bus.$emit('onPin', {
				type: this.user.is_pin > 0 ? 'check' : 'new'
			})

			this.$bus.$on('pinCallback', (call) => {
// console.log('pinCallback', call)
				switch (call.option.type){
					case 'new':
						this.postPin(call)
						break;
					case 'check':
						this.postPinCheck(call)
						break;
				}
			})
		}
		, postPinCheck: async function(data){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_pin_check
					, data: {
						account_id: this.user.account_id
						, pin: data.pin
					}
				})
				if(result.success){
					this.onPinUpdate()
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onPinUpdate: function(){
			this.$bus.$off('pinCallback')

			this.$bus.$emit('onPin', {
				type: 'update'
			})
			this.$bus.$on('pinCallback', (call) => {
// console.log('pinCallback', call)
				switch (call.option.type){
					case 'update':
						this.postPin(call)
						break;
				}
			})
		}
		, postPin: async function(data){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$request.init({
					method: 'post'
					, url: data.option.type == 'new' ? this.$api_url.api_path.post_pin : this.$api_url.api_path.put_pin
					, data: {
						account_id: this.user.account_id
						, pin: data.pin
						, pin_confirm: data.pin_confirm
					}
				})
				if(result.success){
					this.$bus.$emit('updateUser', result.data)
					this.$bus.$emit('notify', { type: 'success', message: result.message})
					this.$bus.$emit('offPin')
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onPinWithdrawal: function(){

			this.$bus.$off('pinCallback')

			this.$bus.$emit('onPin', {
				type: 'check'
			})

			this.$bus.$on('pinCallback', (call) => {
// console.log('pinCallback', call)
				switch (call.option.type){
					case 'check':
						this.onWithdrawal()
						break;
				}
			})
		}
		, getVirtualList: async function() {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					, url: 'management/getVirtualList'
					, data: {
					}
				})
				if (result.success) {
					this.items_virtual = result.data
					this.item_search.virtual_uid = this.items_virtual[0].uid
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, async created() {
		this.$emit('onLoad', this.program)
		await this.getVirtualList()
		await this.getData()
		await this.getHistory()
	}
}
</script>

<style>
.width-fee { width: 60px !important; text-align: right;}
.v-btn__content { color: #333 !important;}
.theme--light.v-icon {
	color: #bbb;
}

.item_close {
	position: absolute; right: 10px; top: 10px
}
</style>
